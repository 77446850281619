<template>
  <div>
    <Navigation
      class="navigation"
      :active-lang="activeLang"
      @formUpdate="updateLang"
    />
    <div class="app-subheader">
      <h2> {{ lang[activeLang]['ACLU OF NEW JERSEY ONLINE FORM TO REQUEST LEGAL ASSISTANCE'] }} </h2>
    </div>
    <div class="content-container">
      <InquiryForm
        v-if="lang"
        class="form-container"
        :active-lang="activeLang"
      />
    </div>
    <Footer
      class="footer"
      :active-lang="activeLang"
    />
  </div>
</template>

<script>
import InquiryForm from './components/InquiryForm.vue'
import Navigation from './components/Navigation.vue'
import Footer from './components/Footer.vue'

export default {
  name: 'App',
  components: {
    InquiryForm,
    Footer,
    Navigation
  },
  data() {
    return {
      activeLang: localStorage.activeLang || 'English'
    }
  },
  created() {
    // Validate activeLang value just incase we change it or someone attemps to change it elsewhere.  
    this.activeLang = this.lang[localStorage.activeLang] && localStorage.activeLang || 'English'
    localStorage.activeLang = this.activeLang
  },
  methods: {
    updateLang(val) {
      this.activeLang = val
      localStorage.activeLang = val
    }
  }
}
</script>

<style>
#app {
  /*font-family: Avenir, Helvetica, Arial, sans-serif;*/
  font-family: Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  display: block;
}

*:-webkit-autofill,
*:-webkit-autofill:hover,
*:-webkit-autofill:focus,
*:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0px 1000px #e5eaf3 inset;
}

body {
  margin: 0;
}

a {
  text-decoration: none;
  color: #3c79b9;
}

a.footer-link {
  color: white;
}

.line-spacer {
  background-color: rgb(226, 226, 226);
  height: 1px;
  border: none;
}

.content-container {
  margin: 0 15px 100px 15px;
  max-width: 1200px;
  min-height: 70vh;
}

.navigation {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.app-subheader {
  background-color: #25255b;
  color: #fff;
  margin: 0;
}

.app-subheader h2 {
  margin: 0 auto 0 auto;
  padding: 8px 0;
  max-width: 1200px;
  text-align: center;
}

.footer {
  margin-top: 25px;
  clear: both;
  position: relative;
  background-color: #25255b;
}

@media screen and (min-width: 700px) {
  .content-container {
    width: 70%;
    margin: 0 auto 100px auto;
  }
  .app-subheader h2 {
    width: 70%;   
    text-align: left;
  }
}
@media screen and (min-width: 1400px) {
  .content-container {
    width: 60%;
    margin: 0 auto 100px auto;
  }
  .app-subheader h2 {
    width: 55%;   
    text-align: left;
  }
}
</style>
