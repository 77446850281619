<template>
  <div class="footer-container">
    <div class="footer-row">
      <h3> WE THE PEOPLE </h3>
      <h3> DARE TO CREATE A MORE PERFECT UNION </h3>
      <div class="socials-container">
        <a
          v-for="(social, index) in socials"
          :key="index"
          :href="social.url"
          :title="social.title"
        >
          <div
            class="round-icon"
            :style="'background-color: ' + social.color"
          >
            <img :src="social.icon">
          </div>
        </a>
      </div>
      <div class="copy-right-text">
        © 2023 American Civil Liberties Union | 
        <a
          class="footer-link"
          href="https://www.aclu-nj.org/en/privacy-statement"
          target="_blank"  
        >PRIVACY STATEMENT
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  props: {
    activeLang: {
      type: String,
      default: 'English'
    }
  },
  data() {
    return {
      socials: [
        {
          url: 'https://www.facebook.com/aclunj',
          icon: require('@/assets/facebook.svg'),
          color: '#3e5c98',
          title: 'Follow the ACLU on Facebook'
        },
        {
          url: 'https://twitter.com/aclunj',
          icon: require('@/assets/twitter.svg'),
          color: '#2fa4f0',
          title: 'Follow the ACLU on Twitter'
        },
        {
          url: 'https://www.instagram.com/aclunj/',
          icon: require('@/assets/insta.svg'),
          color: '#df2e75',
          title: 'Follow the ACLU on Instagram'
        },
        {
          url: 'https://www.youtube.com/channel/UCAQcSEW0eZ3xaGDvv_paCPQ/featured',
          icon: require('@/assets/youtube.svg'),
          color: '#FF0000',
          title: 'Follow the ACLU on Youtube'
        }
      ]
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


.footer-nav-container {
  display: flex;
  gap: 16px;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  max-height: 20%;
}


a {
  text-decoration: none;
  color: #2c3e50;
}

h3 {
  text-align: left;
  margin-top: 0;
  margin-bottom: 0;
  color: #fff;
}

.copy-right-text {
  text-align: left;
  font-size: 0.88rem;
  color: #fff;
}

.footer-row {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 auto;
  padding: 36px 0;
  width: 80%;
}

@media screen and (min-width: 700px) {
  .footer-row {
    width: 50%;
  }
}

.socials-container {
  margin-top: 18px;
  display: flex;
  margin-bottom: 30px;
  min-width: 100%;
}

.footer-sublinks {
  display: flex;
  margin-top: 11px;
  padding-bottom: 60px;
}

.sublink {
  font-size: 0.72rem;
  margin-right: 11px;
  text-decoration: underline;
}

.round-icon {
  background-color: #3e5c98;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 45px;
  height: 45px;
  flex-direction: column;
  margin-right: 16px;
}

img {
  width: 50%;
  height: auto;
}

</style>
