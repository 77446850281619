<template>
  <fade-in>
    <div
      id="FormSurveyHeader"
      class="header-container"
    >
      <h1>{{ lang[activeLang]['WE HAVE RECEIVED YOUR COMPLAINT'] }}</h1>
      <p>
        {{
          lang[activeLang][
            'If you have a few moments'
          ]
        }}
      </p>
    </div>
  </fade-in>
</template>

<script>
import FadeIn from './FadeIn.vue'
//import FormButton from './FormButton.vue'

export default {
  name: 'FormSurveyHeader',
  components: {
    
    FadeIn
  },
  props: {
    activeLang: {
      type: String,
      default: 'English'
    }
  },
  mounted() {
    let elmnt = document.getElementById('FormSurveyHeader')
    elmnt.scrollIntoView()
    window.scrollTo(0,0)
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.header-container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
}

.spacer {
  width: 2%;
}

.header-logo {
  width: 64px;
  height: 64px;
  color: black;
}

h1 {
  text-align: left;
  font-size: 32px;
}

p {
  text-align: left;
}

em {
  color: rgb(214, 88, 88);
}
</style>
