<template>
  <fade-in>
    <div
      id="FormCompleteHeader"
      class="header-container"
    >
      <h1>{{ lang[activeLang]['THANK YOU FOR COMPLETING THE SURVEY'] }}</h1>
      <p>
        <strong>We have received your request for assistance.</strong>
        <br><br>
        Our Intake Department reviews every request for assistance submitted through our online form. Unfortunately, we are not able to reply to each request. If we need additional information, we will contact you directly. If you have not heard from the ACLU-NJ within four weeks, it is unlikely that we are able to assist you. Thank you for your understanding.
      </p>
      <p>
        <strong>What other resources exist?</strong>
        <br><br>
        If you are looking for an attorney, you may contact your <a target="_blank" href="https://tcms.njsba.com/PersonifyEbusiness/Default.aspx?TabID=2011">county bar association</a> for a referral. The New Jersey State Bar Foundation also provides a list of <a target="_blank" href="https://nj.gov/nj/gov/deptserv/">government resources</a> and <a target="_blank" href="https://njsbf.org/contact-us/legal-resourceslinks/">legal resources</a>.
        <br><br>
        If you are looking for free legal assistance with a civil matter, you can contact <a target="_blank" href="https://www.lsnj.org/GetLegalHelp.aspx?Span=ObtengaAyudaJuridica.aspx&Eng=GetLegalHelp.aspx">Legal Services of New Jersey</a>, which provides services to qualifying low-income residents. For criminal matters, you can contact the <a target="_blank" href="https://www.nj.gov/defender/">Office of the Public Defender</a>.
        <br><br>
        The <a target="_blank" href="https://njsbf.org/publications/">New Jersey State Bar Foundation</a>, <a target="_blank" href="https://www.lsnjlaw.org/Pages/default.aspx">Legal Services of New Jersey</a>, and the <a target="_blank" href="https://www.njcourts.gov/selfhelp/catalog.html">New Jersey State Judiciary</a> provide publications regarding various legal topics.
      </p>
      <p>
        <strong>Can the ACLU-NJ advise me about my case?</strong>
        <br><br>
        Unless we accept your case, the ACLU-NJ cannot give you legal advice, answer questions, or provide other types of assistance - for example, reviewing papers or conducting legal research. The organization focuses its resources on the cases we do accept.
        <br><br>
        If you are currently represented by an attorney, legal ethics prohibit us from talking with you about your case without your attorneys permission. If you believe your case merits the ACLU-NJs attention, you should discuss it with your attorney and have the attorney contact us directly.
      </p>
      <p>
        <strong>Can you tell me what the deadlines are for my legal claims?</strong>
        <br><br>
        All legal claims have time deadlines, which may differ depending on who may have violated your rights and how but we cannot give advice on deadlines that apply to your case. For some violations, you may need to file a claim with a government agency before you can sue, and these agencies may have their own deadlines. If you try to take legal action outside of the statute of limitations, you may not be able to pursue your claim in court. The ACLU-NJ cannot give you advice about deadlines that may apply.
      </p>
      <p>
        <strong>If I contact the ACLU-NJ, does that mean I’m a client?</strong>
        <br><br>
        No, contacting the ACLU-NJ does not mean that the ACLU-NJ represents you and does not stop the statute of limitations from running.
      </p>
      <FormButton
        :input="{
          title: 'Return to ACLU New Jersey',
          url: 'https://aclu-nj.org/'
        }"
        :active-lang="activeLang"
      />
    </div>
  </fade-in>
</template>

<script>
import FadeIn from './FadeIn.vue'
import FormButton from './FormButton.vue'

export default {
  name: 'FormCompleteHeader',
  components: {
    FormButton,
    FadeIn
  },
  props: {
    activeLang: {
      type: String,
      default: 'English'
    }
  },
  mounted() {
    let elmnt = document.getElementById('FormCompleteHeader')
    elmnt.scrollIntoView()
    window.scrollTo(0,0)
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.header-container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
}

.spacer {
  width: 2%;
}

.header-logo {
  width: 64px;
  height: 64px;
  color: black;
}

h1 {
  text-align: left;
  font-size: 32px;
}

p {
  text-align: left;
}

em {
  color: rgb(214, 88, 88);
}
</style>
